import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import Cookies from 'js-cookie';
import NotificationAPI from '../api/Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader } from '@fortawesome/free-solid-svg-icons';
import SuccessModal from '../components/successModal'; // Importação da Modal

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

function Notifications() {
  const [notifications, setNotificationsData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    (async () => {
      let csrfToken = Cookies.get('XSRF-TOKEN');
      if (csrfToken == null) window.location.href = process.env.REACT_APP_FULL_DOMAIN + '/';

      NotificationAPI.getAll()
        .then(async ({ data }) => {
          let nots_array = data.map(element => ({
            id: element.id,
            data: JSON.parse(element.data),
            read_at: element.read_at,
            created_at: element.created_at
          }));

          setNotificationsData(nots_array);
        })
        .catch(err => {
          console.log('error on getting notifications:', err);
        });
    })();
  }, []);

  async function handleNotificationRead(not) {
    let data = { id: not.id };

    NotificationAPI.readNotification(data)
      .then(() => {
        setNotificationsData(prevNotifications =>
          prevNotifications.map(n =>
            n.id === not.id ? { ...n, read_at: new Date().toISOString() } : n
          )
        );

        // Atualiza os valores da modal e abre-a
        setModalTitle("Notificação lida");
        setModalMessage("A notificação foi marcada como lida.");
        setModalOpen(true);
      })
      .catch(err => {
        console.log('error on reading notification:', err);
      });
  }

  const readAll = () => {
    let data = {
      id: 'null',
      read_all: true
    };

    NotificationAPI.readNotification(data)
      .then(() => {
        setNotificationsData(prevNotifications =>
          prevNotifications.map(n => ({
            ...n,
            read_at: new Date().toISOString()
          }))
        );

        // Atualiza os valores da modal e abre-a
        setModalTitle("Notificações lidas");
        setModalMessage("Todas as notificações foram marcadas como lidas.");
        setModalOpen(true);
      })
      .catch(err => {
        console.log('error on reading notification:', err);
      });
  };

  return (
    <div className="settings-container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="form-separator" style={{ marginTop: 0 }}>
              <div className="btn-create-appointment">              
                <button onClick={readAll} className="btn-primary">
                  <FontAwesomeIcon icon={faBookReader} /> Ler tudo
                </button>
              </div>
            </div>
          </div>  
          <div className="col-md-12">
            <Timeline position="alternate">
              {notifications.map((notification, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card elevation={2} sx={{ maxWidth: 500 }}>
                      <CardHeader 
                        title={notification.data.title} 
                        subheader={notification.created_at}
                      />
                      <CardContent>
                        {notification.data.description}
                      </CardContent>
                      {notification.read_at == null &&
                        <CardActions disableSpacing>
                          <IconButton 
                            onClick={() => handleNotificationRead(notification)} 
                            aria-label="Marcar como lido"
                          >
                            <MarkChatReadIcon color="warning" />
                          </IconButton>                              
                        </CardActions>
                      }
                      {notification.read_at != null &&
                        <CardContent className="notification-readed">
                          Lida a {notification.read_at}
                        </CardContent>
                      }
                    </Card>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>
        </div>
      </div>

      {/* Modal de sucesso */}
      <SuccessModal 
        open={modalOpen} 
        handleClose={() => setModalOpen(false)} 
        title={modalTitle} 
        message={modalMessage} 
      />
    </div>
  );
}

export default Notifications;
