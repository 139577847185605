import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Ticket from '../api/Ticket.js';
import SuccessModal from '../components/successModal.js'; // Importação da modal

function Tickets() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [ticketData, setTicketData] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar a modal

  useEffect(() => {
    const handleResize = async () => {
      setWindowWidth(window.innerWidth);
    };
    (async () => {
      let csrfToken = Cookies.get('XSRF-TOKEN');
      if (csrfToken == null) window.location.href = process.env.REACT_APP_FULL_DOMAIN + '/';
      await handleResize();
      organizeTicketData();
    })();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTicketCreate = async (e) => {
    e.preventDefault();

    console.log("DATA");
    console.log(uploadedFiles);
    
    const formData = new FormData();
    for (var i = 0; i < uploadedFiles.length; i++) {
      let file = uploadedFiles[i];
      formData.append('files[]', file);
    }

    formData.append('title', ticketData.title);
    formData.append('description', ticketData.description);
    formData.append('total_files', uploadedFiles.length);

    Ticket.create(formData).then((resp) => {
      console.log(resp);
      setModalOpen(true); // Abre a modal após criar o ticket
    });
  };

  const organizeTicketData = async () => {
    setTicketData({
      title: '',
      description: '',
      files: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTicketData({
      ...ticketData,
      [name]: value,
    });
  };

  const handleChangeFiles = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    files.some((file) => {
      uploaded.push(file);
    });
    setUploadedFiles(uploaded);
  };

  return (
    <div className="settings-container">
      <div className="container">
        {ticketData != null &&
          <div className="row">
            <form onSubmit={handleTicketCreate} className="edit-profile-form">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-separator" style={{ marginTop: 0 }}>Ticket</div>
                  </div>
                  <div className="col-md-12">
                    <div className="floating-label">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        value={ticketData.title}
                        onChange={handleChange}
                        placeholder=' '
                        required
                      />
                      <label htmlFor="date" className={ticketData.title ? 'filled' : ''}>Título</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="floating-label">
                      <textarea
                        type="text"
                        id="description"
                        name="description"
                        value={ticketData.description}
                        onChange={handleChange}
                        placeholder=' '
                        required
                      />
                      <label htmlFor="date" className={ticketData.description ? 'filled' : ''}>Descrição</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="floating-label">
                      <input
                        type="file"
                        id="files"
                        name="files"
                        multiple
                        onChange={handleChangeFiles}
                        placeholder=' '
                      />
                      <label htmlFor="date">Anexo</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="btn-container">
                      <button className="btn-primary" type="submit">GRAVAR</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        }
      </div>

      {/* Modal de sucesso */}
      <SuccessModal 
        open={modalOpen} 
        handleClose={() => setModalOpen(false)} 
        title="Ticket criado" 
        message="O seu ticket foi gerado e enviado para a nossa equipa de suporte. Entraremos em contato o mais brevemente possível. Obrigado!" 
      />
    </div>
  );
}

export default Tickets;
