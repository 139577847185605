import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const SuccessModal = ({ open, handleClose, title, message }) => {
  return (
    <Modal 
      open={open} 
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }} 
      aria-labelledby="success-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        {/* Cabeçalho azul com o título em branco */}
        <Box
          sx={{
            bgcolor: "#0c1534",
            color: "white",
            p: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="h6">{title}</Typography>
        </Box>

        {/* Conteúdo da modal */}
        <Box sx={{ p: 3, textAlign: "center" }}>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {message}
          </Typography>
          <Button 
            variant="contained" 
            sx={{ 
              bgcolor: "#0c1534",
              color: "white",
              "&:hover": {
                bgcolor: "#0c1534"
              },
              "&:focus": {
                bgcolor: "#0c1534"
              },
              "&:active": {
                bgcolor: "#0c1534"
              }
            }} 
            onClick={handleClose}
          >
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
