import React, { useState, useEffect } from 'react';
import SuccessModal from '../components/successModal.js'; // Importação da modal

function CreateUserModal({ isOpen, onClose, onSubmit, roles, userInfo }) {
    const [formData, setFormData] = useState({
        email: userInfo != null ? userInfo.email : '',
        code: userInfo != null ? userInfo.code : '',
        name: userInfo != null ? userInfo.name : '',
        username: userInfo != null ? userInfo.username : '',
        password: '',
        phone: userInfo != null ? userInfo.phone : '',
        role: userInfo != null ? userInfo.role.id : roles[0].id,
        carBrand: userInfo != null ? userInfo.carBrand : '',
        carModel: userInfo != null ? userInfo.carModel : '',
        carPlate: userInfo != null ? userInfo.carPlate : '',
        userId: userInfo != null ? userInfo.id : '',
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");

    useEffect(() => {
        console.log('userInfoModal', userInfo);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('formData', formData);

        if (formData.email !== '' && formData.code !== '' && formData.name !== '' && formData.username !== '' && formData.phone !== '' && formData.role !== '') {
            onSubmit(formData);

            // Define os dados para a modal
            setModalTitle(userInfo ? "Utilizador editado com sucesso" : "Utilizador criado com sucesso");
            setModalMessage(
                `O utilizador <strong>${formData.name}</strong> foi ${userInfo ? "editado" : "criado"} com sucesso.`
            );

            // Abre a modal
            setModalOpen(true);
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <>
            <div className="modal">
                <div className="modal-content">
                    <span className="close" onClick={onClose}>
                        &times;
                    </span>
                    <form onSubmit={handleSubmit}>
                        <div className="floating-label">
                            <input
                                type="text"
                                id="code"
                                name="code"
                                value={formData.code}
                                onChange={handleChange}
                                placeholder=' '
                            />
                            <label htmlFor="date" className={formData.email ? 'filled' : ''}>Nº Mec.</label>
                        </div>
                        <div className="floating-label">
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder=' '
                                required
                            />
                            <label htmlFor="date" className={formData.email ? 'filled' : ''}>Email</label>
                        </div>
                        <div className="floating-label">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder=' '
                                required
                            />
                            <label htmlFor="name" className={formData.name ? 'filled' : ''}>Nome</label>
                        </div>
                        <div className="floating-label">
                            <input
                                type="text"
                                id="username"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                placeholder=' '
                                required
                            />
                            <label htmlFor="username" className={formData.username ? 'filled' : ''}>Username</label>
                        </div>
                        <div className="floating-label">
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder=' '
                            />
                            <label htmlFor="phone" className={formData.phone ? 'filled' : ''}>Telemóvel</label>
                        </div>
                        <div className="floating-label">
                            <select
                                id="role"
                                name="role"
                                defaultValue={formData.role !== '' ? formData.role : roles[0].id}
                                onChange={handleChange}
                            >
                                {roles.map((role, i) => (
                                    <option key={i} value={role.id}>{role.display_name}</option>
                                ))}
                            </select>
                            <label htmlFor="role" className="filled select-label">Role</label>
                        </div>
                        <div className="form-separator">Carro</div>
                        <div className="floating-label">
                            <input
                                type="text"
                                id="carBrand"
                                name="carBrand"
                                value={formData.carBrand}
                                onChange={handleChange}
                                placeholder=' '
                            />
                            <label htmlFor="carBrand" className={formData.carBrand ? 'filled' : ''}>Marca</label>
                        </div>
                        <div className="floating-label">
                            <input
                                type="text"
                                id="carModel"
                                name="carModel"
                                value={formData.carModel}
                                onChange={handleChange}
                                placeholder=' '
                            />
                            <label htmlFor="carModel" className={formData.carModel ? 'filled' : ''}>Modelo</label>
                        </div>
                        <div className="floating-label">
                            <input
                                type="text"
                                id="carPlate"
                                name="carPlate"
                                value={formData.carPlate}
                                onChange={handleChange}
                                placeholder=' '
                            />
                            <label htmlFor="carPlate" className={formData.carPlate ? 'filled' : ''}>Matrícula</label>
                        </div>
                        <input 
                            type="hidden" 
                            id="userId"
                            name="userId"
                            value={formData.userId}
                        />
                        <div className="modal-footer">
                            <button className="btn-primary" type="submit">
                                {userInfo ? 'EDITAR' : 'CRIAR'} UTILIZADOR
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Modal de Sucesso */}
            <SuccessModal 
                open={modalOpen} 
                handleClose={() => setModalOpen(false)} 
                title={modalTitle} 
                message={<span dangerouslySetInnerHTML={{ __html: modalMessage }} />} 
            />
        </>
    );
}

export default CreateUserModal;
