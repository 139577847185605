import { Block } from "@mui/icons-material";
import { red } from "@mui/material/colors";

const AccessDenied = () => {
  return (
    <div className="access-denied-container">
    <div className="flex items-center justify-center h-screen w-screen">
      <div className="flex flex-col justify-center items-center text-center p-5">
        <Block className="text-red-500" style={{ fontSize: "8rem" }} />
        <h1 className="text-4xl font-bold text-gray-800 mt-4">Acesso Negado</h1>
        <p className="text-lg text-gray-600 mt-2">
          Parece que não tens permissões para aceder a este conteúdo. <br />
          Por favor, entra em contacto com o departamento de IT se necessitas de acesso.
        </p>
        <a
          href="/dashboard"
          className="mt-4 px-5 py-2 bg-green-500 rounded-lg text-lg transition duration-300 hover:bg-green-600"
        >
          Voltar à Página Inicial
        </a>
      </div>
    </div>
    </div>
  );
};

export default AccessDenied;